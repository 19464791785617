import { IntlShape, injectIntl } from 'react-intl';
import './index.scss';
import InsightsResults from '~/pages/Insights/InsightsResults';
import DocumentsResults from '~/pages/Insights/DocumentsResults';
import {
    InsightsProviderInterface,
    InsightsResultsView,
} from '~/interfaces/contexts';
import { useEntityContext } from '~/hooks';
import { InsightsContext } from '~/context/insights';

const mainClassName = 'cg-insights';

interface InsightsProps {
    intl: IntlShape;
}

const Insights: React.FC<InsightsProps> = ({ intl }) => {
    const { handleTableView, tableView } =
        useEntityContext<InsightsProviderInterface>(InsightsContext);

    return (
        <div className={mainClassName}>
            {tableView === InsightsResultsView.INSIGHTS ? (
                <InsightsResults
                    intl={intl}
                    handleView={() =>
                        handleTableView(InsightsResultsView.DOCUMENTS)
                    }
                />
            ) : (
                <DocumentsResults
                    intl={intl}
                    handleView={() =>
                        handleTableView(InsightsResultsView.INSIGHTS)
                    }
                />
            )}
        </div>
    );
};

const WrappedComponent = injectIntl(Insights);

export default WrappedComponent;
