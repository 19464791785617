import { PropsWithChildren, createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    InsightsProviderInterface,
    InsightsResultsView,
} from '~/interfaces/contexts';

export const InsightsContext = createContext<InsightsProviderInterface | null>(
    null
);

InsightsContext.displayName = 'InsightsContext';

const InsightsProvider = ({ children }: PropsWithChildren) => {
    const location = useLocation();

    const queryLinkView = location?.state?.view || InsightsResultsView.INSIGHTS;
    const [tableView, setTableView] =
        useState<InsightsResultsView>(queryLinkView);

    return (
        <InsightsContext.Provider
            value={{
                tableView,
                handleTableView: setTableView,
            }}
        >
            {children}
        </InsightsContext.Provider>
    );
};

export default InsightsProvider;
